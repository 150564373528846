<template>
  <el-popover
    placement="bottom"
    width="240"
    trigger="click">
    <div class="font-size-slider">
      <span>小</span>
      <div>
        <el-slider
          :min="10"
          :max="20"
          :step="1"
          :format-tooltip="formatTooltip"
          v-model="fontSizeValue"
          @input="onInput">
        </el-slider>
      </div>
      <span>大</span>
    </div>
    <div
      slot="reference"
      style="padding: 12px">
      <em class="iconfont icon-font-size"></em>
    </div>
  </el-popover>
</template>

<script>
export default {
  name: 'FontSizeSelector',
  props: {
    fontColor: {
      type: String,
      default: '#606266'
    }
  },
  data () {
    return {
      fontSizeValue: 16
    }
  },
  methods: {
    formatTooltip (fontSizeValue) {
      return `${fontSizeValue}px`
    },
    onInput (fontSizeValue) {
      const html = document.getElementsByTagName('html')[0]
      html.style.fontSize = `${fontSizeValue}px`
    }
  }
}
</script>

<style lang="scss" scoped>
  .font-size-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

    > span {
      margin: 0 10px;
    }

    > div {
      width: 180px;
    }
  }
</style>
