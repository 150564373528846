var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    { attrs: { placement: "bottom", width: "240", trigger: "click" } },
    [
      _c("div", { staticClass: "font-size-slider" }, [
        _c("span", [_vm._v("小")]),
        _c(
          "div",
          [
            _c("el-slider", {
              attrs: {
                min: 10,
                max: 20,
                step: 1,
                "format-tooltip": _vm.formatTooltip,
              },
              on: { input: _vm.onInput },
              model: {
                value: _vm.fontSizeValue,
                callback: function ($$v) {
                  _vm.fontSizeValue = $$v
                },
                expression: "fontSizeValue",
              },
            }),
          ],
          1
        ),
        _c("span", [_vm._v("大")]),
      ]),
      _c(
        "div",
        {
          staticStyle: { padding: "12px" },
          attrs: { slot: "reference" },
          slot: "reference",
        },
        [_c("em", { staticClass: "iconfont icon-font-size" })]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }